import { createTheme } from "@mui/material"

const colorTheme = {
    palette: {
        primary: {
            main: "#255766 ",
            secondary: "#55B4B5" 

        },
        secondary: {
            main: "#FFEAE0",
            light:"#EDF0F7"
        },
        dark: {
            main: "#000",
            200: 'rgba(158, 158, 158, 0.12)'
        },
        light: {
            main: "#fff",
            200: "#E5E5E5"
        },
        grey: {
            main: "#F5F5F5",
            secondary:"#E5E5E6",
            dark: "#999",
        },
        text: {
            primary: '#3C508B',
            secondary: "#2D3C67"
        },
        orange: {
            main: "rgba(255, 62, 29, 1)"
        },
        tab: {
            main: '#9fb1e8'
        },
        success:{
           main:"#0B8E5F",
           light:"#ECFDF7"
        },
        gradiant:{
            main:"#FFFFFF",
            secondary:"#FFFFFF"
        }
    }

}


export default colorTheme