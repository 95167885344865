import { actions } from "../../utils/constants"


const initialState = {

    vender_data: {},
    vender_loading: false,
    vender_filters: {},

    rawMaterial_data: {},
    rawMaterial_loading: false,
    rawMaterial_filters: {},

    product_data: {},
    product_loading: false,
    product_filters: {},

    department_data: {},
    department_loading: false,
    department_filters: {},

    type_of_sales_data: {},
    type_of_sales_loading: false,
    type_of_sales_filters: {},

    category_data: {},
    category_loading: false,
    category_filters: {},

    update_stock_product_data: {},
    update_stock_product_loading: false,
    update_stock_product_filters: {},

    update_stock_raw_material_data: {},
    update_stock_raw_material_loading: false,
    update_stock_raw_material_filters: {},
}

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_DATA_VENDER_LODING: return { ...state, vender_loading: true, vender_data: [] };
        case actions.FETCH_DATA_VENDER_DATA: return { ...state, vender_loading: false, vender_data: action.value.data, vender_filters: action.value.filters }
        case actions.FETCH_DATA_VENDER_DELETE: return { ...state, vender_data: action.data.value, vender_filters: action.data.filters };

        case actions.FETCH_DATA_RAWMATERIAL_LODING: return { ...state, rawMaterial_loading: true, rawMaterial_data: [] };
        case actions.FETCH_DATA_RAWMATERIAL_DATA: return { ...state, rawMaterial_loading: false, rawMaterial_data: action.value.data, rawMaterial_filters: action.value.filters }
        case actions.FETCH_DATA_RAWMATERIAL_DELETE: return { ...state, rawMaterial_data: action.value, rawMaterial_filters: action.data.filters }

        case actions.FETCH_DATA_PRODUCT_LODING: return { ...state, product_loading: true, product_data: [] };
        case actions.FETCH_DATA_PRODUCT_DATA: return { ...state, product_loading: false, product_data: action.value.data, product_filters: action.value.filters }
        case actions.FETCH_DATA_PRODUCT_DELETE: return { ...state, product_data: action.value, product_filters: action.data.filters }

        case actions.FETCH_DATA_DEPARTMENT_LODING: return { ...state, department_loading: true, department_data: [] };
        case actions.FETCH_DATA_DEPARTMENT_DATA: return { ...state, department_loading: false, department_data: action.value.data, department_filters: action.value.filters }
        case actions.FETCH_DATA_DEPARTMENT_DELETE: return { ...state, department_data: action.value, department_filters: action.data.filters }

        case actions.FETCH_DATA_TYPE_OF_SALES_LODING: return { ...state, type_of_sales_loading: true, type_of_sales_data: [] };
        case actions.FETCH_DATA_TYPE_OF_SALES_DATA: return { ...state, type_of_sales_loading: false, type_of_sales_data: action.value.data, type_of_sales_filters: action.value.filters }
        case actions.FETCH_DATA_TYPE_OF_SALES_DELETE: return { ...state, type_of_sales_data: action.value, type_of_sales_filters: action.data.filters }

        case actions.FETCH_DATA_CATEGORY_LODING: return { ...state, category_loading: true, category_data: [] };
        case actions.FETCH_DATA_CATEGORY_DATA: return { ...state, category_loading: false, category_data: action.value.data, category_filters: action.value.filters }
        case actions.FETCH_DATA_CATEGORY_DELETE: return { ...state, category_data: action.value, category_filters: action.data.filters }

        case actions.FETCH_DATA_UPDATE_STOCK_PRODUCT_LODING: return { ...state, update_stock_product_loading: true, update_stock_product_data: [] };
        case actions.FETCH_DATA_UPDATE_STOCK_PRODUCT_DATA: return { ...state, update_stock_product_loading: false, update_stock_product_data: action.value.data, update_stock_product_filters: action.value.filters }
        case actions.FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE: return { ...state, update_stock_product_data: action.value, update_stock_product_filters: action.data.filters }

        case actions.FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_LODING: return { ...state, update_stock_raw_material_loading: true, update_stock_raw_material_data: [] };
        case actions.FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_DATA: return { ...state, update_stock_raw_material_loading: false, update_stock_raw_material_data: action.value.data, update_stock_raw_material_filters: action.value.filters }
        case actions.FETCH_DATA_UPDATE_STOCK_RAW_MATERIAL_DELETE: return { ...state, update_stock_raw_material_data: action.value, update_stock_raw_material_filters: action.data.filters }

        default:
            return { ...state }
    }
}

export default settingsReducer