const MODULES = {
    DASHBOARD: 1,
    PRODUCT:2,
    VENDOR:3,
    USER:4,
    RAWMATERIALS:5,
    TYPEOFSALES:6,
    PRODUCTIONPLANNING:7,
    STOCK:5,
    CATEGORY:9,
    
}


export default MODULES
