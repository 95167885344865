import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import { Category, Delete, Edit, Info } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
// import { createDepartment, deleteDepartment, fetchDepartments, updateDepartment } from "../../apis/department.api"
import MessageDilog from "../../components/texts/MessageDilog";
import RawMaterialMainUi from "./RawMaterialMainUi";
import RawMaterialCreateController from "./RawMaterialCreateController";
import {
  deleteRawMaterialApi,
  fetchRawMaterialApi,
} from "../../apis/rawMaterial.api";
import { DAY_WEEK_MONTH, SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import RawMaterialInformationContorller from "./RawMaterialInformationContorller";
import { useRef } from "react";
import {
  deleteRawMaterialAction,
  fetchRawMaterialAction,
} from "../../store/actions/setting.Action";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { fetchCategoryApi } from "../../apis/category.api";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();
  const modalkey = "delete";
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const onInfo = () => {
    dispatch(
      openModal(
        <RawMaterialInformationContorller params={params} />,
        "md",
        false,
        "infoupdatee"
      )
    );
  };

  const onEdit = () => {
    dispatch(
      openModal(
        <RawMaterialCreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        false,
        "update"
      )
    );
  };

    const deleteFun = async (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
                // const updateData = settings.rawMaterial_data.filters(item => item._id != params._id)
                // dispatch(deleteRawMaterialAction(updateData, settings.rawMaterial_filters))
                dispatch(callSnackBar(params.name + " Deleted Successfully", SNACK_BAR_VARIETNS.suceess))
                dispatch(closeModal("rawMaterialadd"))

            },
            (err) => {
                setLoading(false)
            }
        ))

    }

    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun}
            title="Alert!" modalId="rawMaterialadd" message={`Are you sure to delete "${params.name || params.title}" ?`}
        />, "sm", false, "rawMaterialadd"))
    }

    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit"
            onClick={onInfo}
        >
            <Info color="info" fontSize="inherit" />
        </IconButton>

        {user.data.role === USER_ROLES.ADMIN ? (<IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>) : ("")}

      {user.data.role === USER_ROLES.ADMIN ? <IconButton disabled={loading} size="inherit" onClick={onDelete}>
        <Delete color="error" fontSize="inherit" />
      </IconButton> : ""}
    </Box>

});

const RawMaterialMainController = () => {
  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state);
  const [list, setList] = useState({});
  const dispatch = useDispatch();
  const title = "Department ";
  const fetchApi = fetchRawMaterialApi;
  const deleteApi = deleteRawMaterialApi;
  const [categoryType, setCategoryType] = useState("all");
  const [categories, setCategories] = useState([]);
  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Raw-Material Name",
        align: "left",
        sort: true,
      },

      {
        id: 4,
        fieldName: "rawMaterial_code",
        label: "RawMaterial Code",
        align: "left",
        sort: false,
        // renderValue: (params, setParams) => params.address?.address,
      },

      {
        id: 3,
        fieldName: "current_stock",
        label: "Current Stock",
        align: "left",
        sort: false,
        renderValue: (params, setParams) => params.current_stock?.quantity,
      },

      {
        id: 2,
        fieldName: "prefered_vendor",
        label: "Prefered Vendor",
        align: "left",
        sort: false,
        renderValue: (params, setParams) =>
          params.prefered_vendor.map((e) => e.name),
      },
      {
        id: 4,
        fieldName: "department",
        label: "Department",
        align: "left",
        sort: false,
        renderValue: (params, setParams) =>
          params.department.map((e) => e.name),
      },


      {
        id: 6,
        fieldName: "hsn_no",
        label: "HSN Number",
        align: "left",
        sort: false,
      },
      {
        id: 7,
        fieldName: "",
        label: "Category",
        align: "left",
        sort: false,
        renderValue: (params, setParams) =>
          params.current_stock?.category?.name,
      },


      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "",
    sortDirection: -1,
    name: "",
  });


  const fetchCategoryFun = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchCategoryApi({ ...filters }),
        (response) => {
          setCategories(response.result);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };


  const getRawMaterialList = () => {
    if (
      !settings.rawMaterial_data ||
      settings.rawMaterial_data.length === 0 ||
      JSON.stringify(filters) != JSON.stringify(settings.rawMaterial_filters)
    ) {
      dispatch(fetchRawMaterialAction(filters));
    }
  };
  useEffect(() => {
    getRawMaterialList();
  }, [filters]);

  
  const handleCategoryChange = (name) => {
    setCategoryType(name);
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageNo: 1,
      name: name === "all" ? null : name,
    }));
  };

  // useEffect(() => {
  //     fetchList();
  // }, [filters]);

  useEffect(() => {
    fetchCategoryFun();
  }, []);

  return (
    <>
      <RawMaterialMainUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={settings.rawMaterial_loading}
        list={settings.rawMaterial_data}
        columns={columns}
        categoryType={categoryType}
        setCategoryType={setCategoryType}
        categories={categories}
        onCategoryChange={handleCategoryChange}
      />
    </>
  );
};
export default RawMaterialMainController;
