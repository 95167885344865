import React from 'react'
import {AddShoppingCart, Dashboard, DashboardCustomize, Groups2Outlined, Settings, ShowChart} from "@mui/icons-material"
import SignInController from './pages/signin/SignInController'
import { Navigate } from 'react-router-dom'
import NotAllowedComponent from '../src/components/layouts/NotAllowedComponent'
import { getDefaultRedirect } from './utils/routinghelper'
import AppContainer from './components/layouts/common/AppContainer'
import MODULES from './utils/module.constant'
import SignInUI from './pages/signin/SignInUi'
import { USER_ROLES } from './utils/constants'
import PagenotFound from './components/layouts/PagenotFound'
import { Title } from '@mui/icons-material'
import DepartmentController from './pages/department/DepartmentController'
import ApartmentIcon from '@mui/icons-material/Apartment';
import VendorListController from './pages/vendor/VendorListController'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BentoIcon from '@mui/icons-material/Bento';
import CreateUserController from './pages/user/CreateUserController';
import RawMaterialMainController from './pages/raw material/RawMaterialMainController';
import ListController from './pages/user/UserLIstController';
import CreateProductUi from './pages/products/CreateProductUi'
import ProductListUi from './pages/products/ProductListUi'
import CreateProductController from './pages/products/CreateProductController'
import ProductListController from './pages/products/ProductListController'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ChecklistIcon from '@mui/icons-material/Checklist';
import TypeOfSalesMainController from './pages/type_of_sales/TypeOfSalesMainController'
import ProductionListController from './pages/Production/ProductionListController'
import LeadListController from './pages/leads/LeadListController'
import DashboardListController from './pages/dashboard/DashboardListController'
import CategoryTypeMainController from './pages/categories/CategoryTypeMainController'
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';


const loggedInPathElementRender = (login, allowed = [], permittedModule = [], Component, defaultRedirect, hideInPannel = false) => {

  const obj = {
      hideInPannel,
      element: Component,
  }
  if (!login) {
      obj['element'] = <Navigate replace to={'/sign-in'} />
  } else {

      let found = false
      for (let module of allowed) {
          for (let allowedModule of permittedModule) {
              if (module == allowedModule) {
                  found = true
                  break;
              }
          }
      }
      if (!found) {
          obj['hideInPannel'] = true
          obj['element'] = <NotAllowedComponent />
      }
      
  }
  return obj
}


const defineRoutes = (user) => {
  const allowedModules = user.data.modules ?? [1,2,3,4,5,6,7,8,9]
  const defaultRedirect = getDefaultRedirect(allowedModules)
  return ([
    {
      path: "sign-in",
      element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to={defaultRedirect} />,
      hideInPannel: true

    },
    {
      path: "",
      element: user.isLoggedIn ? <Navigate replace to={defaultRedirect} /> : <Navigate replace to="/sign-in" />,
      hideInPannel: true

    },
    {
      path: "dashboard",
      icon: <DashboardCustomize />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules,<AppContainer ><DashboardListController/></AppContainer> , defaultRedirect),
      hideInPannel: false,

    },

    {
      path: "products",
      icon: <AddShoppingCart />,
      title: "Products",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PRODUCT], allowedModules,<AppContainer ><ProductListController/></AppContainer> , defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.PURCHASE,

    },

    {
      path: "raw-material",
      icon: <Inventory2Icon />,
      title: "Raw Material",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.RAWMATERIALS], allowedModules,<AppContainer ><RawMaterialMainController/></AppContainer> , defaultRedirect),
      hideInPannel: false,

    },
    {
      path: "Stock",
      icon: <ShowChart />,
      title: "Stocks In/Out",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.STOCK], allowedModules,<AppContainer ><LeadListController/></AppContainer> , defaultRedirect),
      hideInPannel: false,

    },

    {
      path: "production",
      icon: <PrecisionManufacturingIcon />,
      title: "Production Planning",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PRODUCTIONPLANNING], allowedModules,<AppContainer ><ProductionListController/></AppContainer> , defaultRedirect),
      hideInPannel:  user.data.role === USER_ROLES.ADMIN || user.data.role === USER_ROLES.PRODUCTION ? false : true,

    },

    // {
    //   path: "vendor",
    //   icon: <PersonSearchIcon />,
    //   title: "Vendor",
    //   ...loggedInPathElementRender(user.isLoggedIn,[MODULES.VENDOR], allowedModules,<AppContainer > <VendorListController /></AppContainer> , defaultRedirect),
    //   // element:  user.isLoggedIn ?<AppContainer ></AppContainer>:<Navigate replace to="/sign-in" />,
    //   hideInPannel: false,
    // },
    
    // {
    //   path: "department",
    //   icon: <ApartmentIcon />,
    //   title: "Department",
    //   ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules,<AppContainer ><DepartmentController/></AppContainer> , defaultRedirect),
    //   hideInPannel: false,

    // },

    // {
    //   path: "type_of_sales",
    //   icon: <ChecklistIcon />,
    //   title: "Type Of Sales",
    //   ...loggedInPathElementRender(user.isLoggedIn,[MODULES.TYPEOFSALES], allowedModules,<AppContainer > <TypeOfSalesMainController /></AppContainer> , defaultRedirect),
    //   hideInPannel: false,

    // },

   
    // {
    //   path: "users",
    //   icon: <Inventory2Icon />,
    //   title: "Users",
    //   ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules,<AppContainer ><ListController/></AppContainer>, defaultRedirect),
    //   hideInPannel: false ,
    //   children: [
    //     {
    //       path:"user",
    //       icon:Inventory2Icon,
    //       title:"USER",
    //       ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <ListController />, defaultRedirect),
    //       hideInPannel: false ,
    //     }
    //   ]
    // },

    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules,<AppContainer ><ListController/></AppContainer> , defaultRedirect),
      hideInPannel:  user.data.role === USER_ROLES.ADMIN ? false : true,

    },

    {
      path: "vendor",
     icon: <PersonSearchIcon/> ,
      title: "Vendor",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.VENDOR], allowedModules, <AppContainer ><VendorListController/></AppContainer>, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.ADMIN || user.data.role === USER_ROLES.PURCHASE ? false : true,
    },

    {
        path: "settings",
        icon: <Settings />,
        title: "Settings",
        ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules,<AppContainer />, defaultRedirect),
        hideInPannel: user.data.role === USER_ROLES.ADMIN ? false : true,
      children: [

        {
          path: "department",
          icon: ApartmentIcon ,
          title: "Department",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <DepartmentController />, defaultRedirect),
        },
        {
          path: "type_of_sales",
         icon: ChecklistIcon ,
          title: "Type Of Sales",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.TYPEOFSALES], allowedModules,<TypeOfSalesMainController />, defaultRedirect),
        },
        {
          path: "category_type",
          icon: CategoryIcon,
          title: "Category",
          ...loggedInPathElementRender(user.isLoggedIn,[MODULES.CATEGORY], allowedModules,<CategoryTypeMainController /> , defaultRedirect),
    
        },
      ]
    },
    
    {
      path: "*",
      hideInPannel: true,
      element: !user.isLoggedIn ? <Navigate replace to={"/sign-in"} /> : <PagenotFound />,
    },

  ])

}

export default defineRoutes