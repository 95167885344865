import { Autocomplete, Box, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, ListItem, Typography } from "@mui/material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import CustomInput from "../../components/inputs/CustomInputs"
import { memo, useEffect } from "react"
import { Close } from "@mui/icons-material"
import SubmitButton from "../../components/button/SubmitButton"
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment"
import { DAY_WEEK_MONTH } from "../../utils/constants"
import { findNameByRole, titleCase } from "../../utils/main"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { fetchDepartments } from "../../apis/department.api"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop"
import { fetchvendorApi } from "../../apis/vendor.api"
import { fetchCategoryApi } from "../../apis/category.api"

const RawMaterialCreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, isModal, handleAreaModalClose }) => {
    const allowed = [...Object.keys(DAY_WEEK_MONTH).map((key) => ({ label: titleCase(key), _id: DAY_WEEK_MONTH[key] }))]
    const handleLeadtime = (e) => {

        setFields({
            ...fields,
            err: '',
            lead_time: e.target.value
        })
    }

    return <>
        {!isModal &&
            <CustomDialog
                id={`${isUpdate ? "update" : "rawMaterial"}`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={`${isUpdate ? "Update" : "Create"} ${title}`}
                closeText="Close"
                confirmText={`${isUpdate ? "Update" : "Create"}`}
            >
                {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                    <>
                        {<CustomInput
                            autoFocus={true}
                            disabled={loading}
                            value={fields.name}
                            onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                            type="text"
                            label={"RawMaterial Name*"}
                        />}

                        {<CustomInput
                            disabled={loading}
                            value={fields.rawMaterial_code}
                            onChange={(e) => setFields({ ...fields, err: '', rawMaterial_code: e.target.value })}
                            type="text"
                            label={"RawMaterial Code*"}
                        />}

                        {<CustomInput
                            disabled={loading}
                            value={fields.hsn_no}
                            onChange={(e) => setFields({ ...fields, err: '', hsn_no: e.target.value })}
                            type="number"
                            label={"HSN Number*"}
                        />}

                        {<PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            <AsyncDropDown
                                defaultVal={
                                    fields.department ? {

                                        _id: fields.department._id,
                                        name: fields.department.name,
                                    }
                                        : null
                                }
                                lazyFun={async (para) => await fetchDepartments({ ...para, allStatus: true })}
                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                value={fields?.department}
                                onChange={async (changedVal) => {
                                    setFields({ ...fields, department: changedVal ? [changedVal._id] : null });
                                }}

                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Department"} {...params} margin="none" />}
                            />
                        </PaddingBoxInDesktop>}

                        {<PaddingBoxInDesktop mt={4} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            <AsyncDropDown
                                defaultVal={
                                    fields.prefered_vendor
                                        ? {

                                            _id: fields.prefered_vendor?._id,
                                            name: fields.prefered_vendor?.name,
                                        }
                                        : null
                                }
                                lazyFun={async (para) => await fetchvendorApi({ ...para, allStatus: true })}
                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                value={fields?.prefered_vendor}
                                onChange={async (changedVal) => {
                                    setFields({ ...fields, prefered_vendor: changedVal ? [changedVal._id] : null });
                                }}

                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Prefered Vendor"} {...params} margin="none" />}
                            />
                        </PaddingBoxInDesktop>}

                        {<Box mt={4}>
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown
                                    defaultVal={
                                        fields.alternative_vendor
                                            ? {
                                                _id: fields.alternative_vendor?._id,
                                                name: fields.alternative_vendor?.name,
                                            }
                                            : null
                                    }
                                    lazyFun={async (para) => await fetchvendorApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    value={fields?.alternative_vendor}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, alternative_vendor: changedVal ? [changedVal._id] : null });
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Alternative Vendors"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop></Box>}

                        {<Box mt={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Autocomplete
                                disableClearable
                                options={allowed}
                                value={findNameByRole(fields.role)}
                                onChange={(e, newVal) => {
                                    setFields({ ...fields, role: newVal ? newVal._id : null, parent_id: null })
                                }}
                                sx={{ width: "49%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                renderInput={(params) => <CustomInput placeholder="Select Day/Week*" {...params} label="Select Day Or Week*" margin="dense" />}
                            />
                            <CustomInput
                                disabled={loading}
                                value={fields.lead_time}
                                sx={{ width: "49%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}

                                onChange={(e) => handleLeadtime(e)}
                                type="number"
                                label={"Lead Time*"}
                            />

                        </Box>}

                        {<CustomInput
                            disabled={loading}
                            value={fields.min_stock_quantity}
                            onChange={(e) => setFields({ ...fields, err: '', min_stock_quantity: e.target.value })}
                            type="number"
                            label={"MSQ*"}
                        />}

                    </>
                }
            </CustomDialog>
        }
    </>
}
export default memo(RawMaterialCreateUi)