import { Autocomplete, Box, Button, ListItem, Typography } from '@mui/material';
import React, { useState } from 'react'
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
import AsyncDropDown from '../../components/inputs/AsyncDropDown';
import CustomInput from '../../components/inputs/CustomInputs';
import { StyledSearchBar } from '../../components/inputs/SearchBar';
import { fetchRawMaterialApi } from '../../apis/rawMaterial.api';

const AddMaterialUi = ({ fields, setFields, index, loading }) => {
    const fixedOptions = [];
    const [value, setValue] = useState([]);
    return (
        <Box >
            {fields?.rawMaterials?.length > 0 && fields.rawMaterials.map((val, inx) => {
                if (!fields.rawMaterials[inx]) return null;

                return (
                    <Box key={inx} >

                        <PaddingBoxInDesktop mt={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            <AsyncDropDown
                            defaultVal={
                                fields.rawMaterials
                                    ? {
                                        
                                        _id: fields.rawMaterials[inx].rawMaterialId?._id,
                                        name: fields.rawMaterials[inx].rawMaterialId?.name,
                                    }
                                    : []
                            }
                                lazyFun={async (para) => await fetchRawMaterialApi({ ...para, allStatus: true })}
                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    const updatedRawMaterials = [...fields.rawMaterials];
                                    updatedRawMaterials[inx] = {
                                        ...updatedRawMaterials[inx],
                                        rawMaterialId: changedVal ? [changedVal._id] : null,
                                    };
                                    setFields({ ...fields, rawMaterials: updatedRawMaterials });
                                }}

                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Material"} {...params} margin="none" />}
                            />
                        </PaddingBoxInDesktop>
                        <CustomInput
                            disabled={loading}
                            value={fields?.rawMaterials[inx]?.min_of_quantity}
                            onChange={(e) => {
                                const updatedRawMaterials = [...fields.rawMaterials];
                                updatedRawMaterials[inx] = {
                                    ...updatedRawMaterials[inx],
                                    min_of_quantity: e.target.value,
                                };
                                setFields({ ...fields, err: "", rawMaterials: updatedRawMaterials });
                            }}
                            type="number"
                            label={"MOQ*"}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};


export default AddMaterialUi