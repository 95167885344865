import { Autocomplete, Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, ListItem, Typography } from "@mui/material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import CustomInput from "../../components/inputs/CustomInputs"
import { memo, useEffect } from "react"
import { Close } from "@mui/icons-material"
import SubmitButton from "../../components/button/SubmitButton"
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment"
import { DAY_WEEK_MONTH } from "../../utils/constants"
import { findNameByRole, titleCase } from "../../utils/main"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop"
import { fetchDepartments } from "../../apis/department.api"
import { fetchvendorApi } from "../../apis/vendor.api"
import AddMaterialUiProduct from "./AddMaterialUiProduct"
import { fetchCategoryApi } from "../../apis/category.api"

const CreateProductUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, isModal, index, handleAreaModalClose }) => {
    const allowed = [...Object.keys(DAY_WEEK_MONTH).map((key) => ({ label: titleCase(key), _id: DAY_WEEK_MONTH[key] }))]
    const handleLeadtime = (e) => {

        setFields({
            ...fields,
            err: '',
            lead_time: e.target.value
        })
    }

return <>

        <CustomDialog
            id={`${isUpdate ? "productupdate" : "product"}`}
            loading={loading}
            err={fields?.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >
            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields?.product_name}
                        onChange={(e) => setFields({ ...fields, err: '', product_name: e.target.value })}
                        type="text"
                        label={"Product Name*"}
                    />}
                    {<CustomInput
                        disabled={loading}
                        value={fields?.product_code}
                        onChange={(e) => setFields({ ...fields, err: '', product_code: e.target.value })}
                        type="text"
                        label={"Product Code*"}
                    />}

                    { <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Autocomplete
                            disableClearable
                            options={allowed}
                            defaultValue={"Day"}
                            value={findNameByRole(fields?.role)}
                            onChange={(e, newVal) => {
                                setFields({ ...fields, role: newVal ? newVal._id : null, parent_id: null })
                            }}
                            sx={{ width: "49%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                            renderInput={(params) => <CustomInput placeholder="Select day/Week*" {...params} label="Select Day Or Week*" margin="dense" />}
                        />
                        <CustomInput
                            disabled={loading}
                            sx={{ width: "49%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                            value={fields?.lead_time}
                            onChange={(e) => handleLeadtime(e)}
                            type="number"
                            label={"Assembling Time*"}
                        />

                    </Box>}

                    {<CustomInput
                        disabled={loading}
                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        value={fields?.current_stock}
                        onChange={(e) => setFields({ ...fields, err: '', current_stock: e.target.value })}
                        type="number"
                        label={"Current Stock*"}
                    />}

                    {<PaddingBoxInDesktop mt={3} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                        <AsyncDropDown
                            defaultVal={
                                fields.department ? {

                                    _id: fields.department._id,
                                    name: fields.department.name,
                                }
                                    : null
                            }
                            lazyFun={async (para) => await fetchDepartments({ ...para, allStatus: true })}
                            OptionComponent={({ option, ...rest }) => {
                                return <ListItem {...rest}>{option.name}</ListItem >
                            }}
                            value={fields?.department}
                            onChange={async (changedVal) => {
                                setFields({ ...fields, department: changedVal ? [changedVal._id] : null });
                            }}

                            titleKey={'name'}
                            valueKey={"_id"}
                            InputComponent={(params) => <StyledSearchBar placeholder={"Select Department"} {...params} margin="none" />}
                        />
                    </PaddingBoxInDesktop>}
                    

                    {/* {<PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                        <AsyncDropDown
                            defaultVal={
                                fields.vendor
                                    ? {

                                        _id: fields.vendor?._id,
                                        name: fields.vendor?.name,
                                    }
                                    : null
                            }
                            lazyFun={async (para) => await fetchvendorApi({ ...para, allStatus: true })}
                            OptionComponent={({ option, ...rest }) => {
                                return <ListItem {...rest}>{option.name}</ListItem >
                            }}
                            value={fields?.vendor}
                            onChange={async (changedVal) => {
                                setFields({ ...fields, vendor: changedVal ? [changedVal._id] : null });
                            }}

                            titleKey={'name'}
                            valueKey={"_id"}
                            InputComponent={(params) => <StyledSearchBar placeholder={"Select Vendor"} {...params} margin="none" />}
                        />
                    </PaddingBoxInDesktop>} */}

                    {<CustomInput
                        disabled={loading}
                        value={fields.product_price}
                        onChange={(e) => setFields({ ...fields, err: '', product_price: e.target.value })}
                        type="number"
                        label={"Product Price*"}
                    />}

                    {<Box >
                            <AddMaterialUiProduct fields={fields} setFields={setFields} index={index} />
                        </Box>}

                    {<Box mt={2} width={"100%"} display={"flex"} justifyContent={"center"}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setFields((data) => {
                                    let arr = [...data.requiredRawMaterials];
                                    arr.push({
                                        rawMaterialId: "",
                                        rawMaterialName: "",
                                        quantity: null
                                    });
                                    return { ...data, requiredRawMaterials: arr };
                                });
                            }}

                        >
                            <Typography variant="h6">Add More Materials</Typography>
                        </Button>
                    </Box>}
                </>
            }
        </CustomDialog>

    </>
}
export default memo(CreateProductUi)