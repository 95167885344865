import { memo, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { callApiAction } from "../../store/actions/commonAction"
import { Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { createDepartment, deleteDepartment, fetchDepartments, updateDepartment } from "../../apis/department.api"
import MessageDilog from "../../components/texts/MessageDilog"
import TypeOfSalesCreateController from "./TypeOfSalesCreateController"
import TypeOfSalesMainUi from "./TypeOfSalesMainUi"
import { deleteTypeofSales, fetchTypeofSaless, updateTypeofSales } from "../../apis/typeofsale.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { fetchTypeOfSalesAction } from "../../store/actions/setting.Action"

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()
    const modalkey = "delete"
    const [loading, setLoading] = useState(false)
    const { settings } = useSelector((state) => state)

    const onEdit = () => {
        dispatch(openModal(<TypeOfSalesCreateController id={params._id}
        />, "sm", false, "updatetypofsales"))
    }

    const deleteFun = async (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteTypeofSales({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
                dispatch(callSnackBar(params.type + " Deleted Successfully", SNACK_BAR_VARIETNS.suceess))
                dispatch(closeModal("messagedialogdeletee"))

            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const onDelete = () => {
        
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" modalId="messagedialogdeletee" message={`Are you sure to delete "${params.type || params.title}" ?`} />, "sm",false, "messagedialogdeletee"))
    }
    

    // const onDelete = () => {
    //     dispatch
    // }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        <IconButton 
        disabled={loading}
         size="inherit" 
        onClick={onDelete}
        >
            <Delete color="error" fontSize="inherit" />
        </IconButton>

    </Box>
})






const TypeOfSalesMainController = () => {
    const dispatch = useDispatch()
    const title = "Type Of sale"
    const fetchApi = fetchTypeofSaless
    const deleteApi = deleteTypeofSales
    const updateApi = updateTypeofSales
    const { settings } = useSelector((state) => state)


    const columns = useMemo(() => [

        { id: 1, fieldName: 'type', label: 'Type Of Sale', align: "left", sort: true, renderValue: (params, setParams) => <Typography textTransform="capitalize">{params.type}</Typography> },
        // { id: 2, fieldName: 'has_a_distributor', label: 'Has a Destributor', align: "left", sort: true, renderValue: (params, setParams) => <Typography textTransform="capitalize">{params.has_a_distributor ? "Yes" : "No"}</Typography> },
        {
            id: 5,
            fieldName: '',
            label: 'Action',
            align: "right",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);
    


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['type'],
        sort: '',
        sortDirection: -1

    })

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const getVendorList = () => {
        if (!settings.type_of_sales_data || settings.type_of_sales_data.length === 0 || JSON.stringify(filters) != JSON.stringify(settings.type_of_sales_filters)) {
          dispatch(fetchTypeOfSalesAction(filters));
        }
      }
      useEffect(() => {
        getVendorList()
    
      }, [filters])


    return (
        <>
            <TypeOfSalesMainUi
                title={title}

                filters={filters}
                setFilters={setFilters}
                loading={settings.type_of_sales_loading}
                list={settings.type_of_sales_data}
                columns={columns}


            />

        </>
    )
}
export default TypeOfSalesMainController